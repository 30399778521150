<template>
  <b-card>
    <g-form @submit="save">
      <b-row class="p-0 mb-1">
        <b-col
          md="5"
        >
          <b-button
            :variant="file.name ? 'success' : 'adn'"
            data-action-type="new"
            class="btn-sm p-75"
            @click="
              (v) => {
                openModal();
              }
            "
          >
            {{ file.name ? $t("hasAttachMents") : $t("attachFile") }}
            <feather-icon
              icon="UploadIcon"
              class="ml-50"
            />
          </b-button>
          <a
            v-if="selectedItem.attachmentUrl"
            :href="selectedItem.attachmentUrl"
            class="ml-1 btn-sm btn btn-outline-adn p-75"
            @click.prevent="downloadItem(selectedItem)"
          >
            {{ $t("downloadAttachedFile") }}
            <feather-icon
              icon="DownloadIcon"
              class="ml-50"
            />
          </a>
        </b-col>
      </b-row>
      <hr class="border-bottom-primary border-darken-2">
      <b-modal
        ref="upload-modal"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        centered
        size="lg"
        hide-footer
        :title="$t('attachFile')"
      >
        <g-form>
          <!-- operations -->
          <b-row>
            <b-col cols="12">
              <div>
                <input
                  type="file"
                  class="excel-upload-input"
                  accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip, .xlsx, .PNG"
                  @change="handleFileUpload($event)"
                >
                <b-alert
                  v-if="file"
                  show
                  fade
                  class="mt-2 text-center"
                  variant="success"
                >
                  <div class="alert-body">
                    <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                  </div>
                </b-alert>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <b-button
                class="mr-1"
                variant="relief-primary"
                @click="closeModal"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                {{ $t("save") }}
              </b-button>
              <b-button
                variant="secondary"
                data-action-type="delete"
                :disabled="!file"
                @click="
                  () => {
                    removeAttachment();
                  }
                "
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                {{ $t("cancel") }}
              </b-button>
            </b-col>
          </b-row>
        </g-form>
      </b-modal>
      <b-row>
        <b-col
          v-if="selectedItem.id > 0"
          md="4"
        >
          <g-field
            id="code"
            :value.sync="selectedItem.code"
            label-text="code"
            type="number"
            name="code"
          />
        </b-col>

        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            name="date"
            :disabled="true"
          />
        </b-col>

        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              rules="required"
              label-text="employee"
              field="select"
              name="employee"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.startDate"
            label-text="startVacationForm"
            name="date"
            @change="setVacationNumberOfDays()"
          />
        </b-col>

        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.endDate"
            label-text="endVacationAt"
            name="date"
            @change="setVacationNumberOfDays()"
          />
        </b-col>

        <b-col md="4">
          <g-field
            id="vacationNumberOfDays"
            :value.sync="helper.vacationNumberOfDays"
            label-text="vacationNumberOfDays"
            name="code"
            disabled
          />
        </b-col>

        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.typeVacationId"
              :dir="isRight ? 'rtl' : 'ltr'"
              rules="required"
              label-text="VacationType"
              field="select"
              name="VacationType"
              :options="VacationTypes"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <g-field
            id="employeeVacationRemainingValue"
            :value.sync="helper.employeeVacationRemainingValue"
            label-text="employeeVacationRemainingValue"
            disabled
          />
        </b-col>

        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.adoptedFromId"
              :dir="isRight ? 'rtl' : 'ltr'"
              rules="required"
              label-text="adoptedFrom"
              field="select"
              name="adoptedFrom"
              :options="adoptedFroms"
              :filterFn="(item) => item.isDepartment === true"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- notes -->
      <b-row class="pt-1">
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- accept button -->
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="relief-primary"
            data-action-type="save"
            @click="save('saveOnly')"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ this.$t('save') }}
          </b-button>

          <b-button
            class="ml-1"
            variant="outline-primary"
            data-action-type="save"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ this.$t('saveAndPrint') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import {} from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';
import saveAs from 'file-saver';

export default {
  mixins: [reportMixin],
  props: ['id'],
  data() {
    return {
      VacationTypes: [],
      adoptedFroms: [],
      employees: [],
      selectedItem: {},
      file: '',
      helper: {
        vacationNumberOfDays: 0,
        employeeVacationRemainingValue: 0
      }
    };
  },
  watch: {
    'selectedItem.employeeId'() {
      this.getVacationStats(this.selectedItem.employeeId, this.selectedItem.typeVacationId);
    },
    'selectedItem.typeVacationId'() {
      this.getVacationStats(this.selectedItem.employeeId, this.selectedItem.typeVacationId);
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    // get object in case edit
    if (this.id > 0) {
      this.getSelected();
    }
    this.getEmployee();
    this.getVacationType();
  },
  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    validateDates() {
      this.selectedItem.transactionDate = this.selectedItem.transactionDate ?? this.today;
      this.selectedItem.startDate = this.selectedItem.startDate ?? this.today;
      this.selectedItem.endDate = this.selectedItem.endDate ?? this.today;
    },
    // get vacation type
    getVacationType() {
      this.get({ url: 'TypesVacations' }).then((data) => {
        this.VacationTypes = data;
      });
    },
    // get object from server side
    getSelected() {
      this.get({ url: 'RequestVacation', id: this.id }).then((data) => {
        this.selectedItem = data;
    });
    },

    // get all employees
    getEmployee() {
      this.get({ url: 'employees' }).then((data) => {
        this.employees = data.filter(x => !x.isSuspended);
        this.adoptedFroms = data.filter(x => !x.isSuspended);
      });
    },

    // save data to db
    save(type) {
      // validation before save
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      this.validateDates();
      if (this.selectedItem.endDate < this.selectedItem.startDate) {
        this.doneAlert({ text: this.$t('startDateCantbeGreaterThanEndDate'), type: 'warning', timer: 10000 });
        return;
      }
      if (this.helper.vacationNumberOfDays > this.helper.employeeVacationRemainingValue) {
        this.doneAlert({ text: this.$t('vacationNumberNotEnough'), type: 'warning', timer: 10000 });
        return;
      }
      this.selectedItem.branchId = this.branchId;
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'RequestVacation',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          if (type === 'saveAndPrint') this.print(this.selectedItem.id);
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.$router.push({ name: 'vacationRequest' });
        });
      } else {
        this.create({
          url: 'RequestVacation',
          data: this.selectedItem,
        }).then((dataId) => {
          if (type === 'saveAndPrint') this.print(dataId);
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.$router.push({ name: 'vacationRequest' });
        });
      }
    },
    print(itemId) {
      const reportName = this.isRight ? 'VacationRequest-ar' : 'VacationRequest-en';
      const printedItem = {
        vacationRequestId: itemId,
      }
      this.printReport(reportName, printedItem);
    },
    openModal() {
      this.$refs['upload-modal'].show();
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageFileExtension = file.name.split('.').pop();
      this.toBase64(file).then((file1) => {
        this.selectedItem.imageBase64Content = file1.split(',').pop();
      });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name
        .split('.')
        .pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t(
            'Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .xlsx, .PNG, .zip suffix files'
          ),
          type: 'error',
        });
        this.file = '';
        return false;
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t('fileSizeExceedsFiveMiB'),
          type: 'error',
        });
        this.file = '';
        return false;
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|xlsx|PNG|zip)$/.test(file.name);
    },
    getVacationStats(employeeId, vacationId) {
      if (!employeeId || !vacationId) {
        this.helper.employeeVacationRemainingValue = 0;
        return;
      }
      this.get({ url: `RequestVacation/GetEmployeeVacationRemainingValue?employeeId=${employeeId}&vacationId=${vacationId}` }).then((data) => {
        this.helper.employeeVacationRemainingValue = data;
        if (this.id > 0) this.helper.employeeVacationRemainingValue += this.helper.vacationNumberOfDays;
      });
    },
    setVacationNumberOfDays() {
      if (!this.selectedItem.startDate || !this.selectedItem.endDate) return;
      if (this.selectedItem.startDate > this.selectedItem.endDate) {
        this.helper.vacationNumberOfDays = 0
        return
      }
      const dayInMs = (1000 * 60 * 60 * 24);
      const difference = (new Date(this.selectedItem.endDate) - new Date(this.selectedItem.startDate)) / dayInMs;
      this.helper.vacationNumberOfDays = Math.round(difference) + 1;
    },
  },
};
</script>
<style>
.header {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #a074c7;
  border-radius: 5px;
}

.hide-code {
  display: none;
}

.header h4 {
  color: white;
}
</style>
